<template>
	<q-item class="text-left q-px-none">
		<!-- Text (Textarea)-->
		<q-input
			v-if="type == 'TEXT'"
			class="full-width"
			v-model="current_value"
			outlined
			autogrow
			type="textarea"
			:label='is_required ? label + " *" : label'
			:disable="is_disabled"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
			/>

		<!-- Email (Input)-->
		<q-input
			v-if="type == 'EMAIL'"
			outlined
			class="full-width"
			v-model="current_value"
			type="email"
			:label='is_required ? label + " *" : label'
			:disable="is_disabled"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
		/>
		<!-- Number (Input)-->
		<q-input
			v-if="type == 'NUMERIC'"
			outlined
			class="full-width"
			v-model="current_value"
			type="number"
			:label='is_required ? label + " *" : label'
			:disable="is_disabled"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
		<!-- CHECKBOX -->
		<q-item-section v-if="type == 'CHECKBOX'" >
			<q-item-label class="alert" v-if="is_required && !current_value">{{label}} *</q-item-label>
			<q-item-label v-else> {{label}} </q-item-label>
		</q-item-section>
		<q-item-section avatar class="flex items-center" v-if="type == 'CHECKBOX'" >
			<q-checkbox v-model="current_value" :disable="is_disabled" />
		</q-item-section>

		<!-- Singleselect -->
		<q-select
			v-if="type == 'SINGLESELECT'"
			class="full-width"
			:label='is_required ? label + " *" : label'
			v-model="current_value"
			:options="options"
			:option-label="(item) => item.value"
			:disable="is_disabled"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
		/>

		<!-- Multiselect -->
		<q-select
			v-if="type == 'MULTISELECT'"
			class="full-width"
			multiple
			v-model="current_value"
			:label='is_required ? label + " *" : label'
			:options="options"
			:disable="is_disabled"
			:option-label="(item) => item.value"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
		/>

		<!-- Attachment -->
		<div v-if="type == 'ATTACHMENT'" class="full-width">
			<q-file
				class="full-width"
				v-model="attachment_value"
				multiple
				outlined
				color="primary"
				:label='is_required ? label + " *" : label'
				:disable='is_disabled'
				@input="uploadAttachment(attachment_value)"
				:error="errors['has_' + label + '_error']"
				:error-message="errors[label +'_error_message']"
			>
				<template v-slot:prepend>
					<q-icon name="o_attach_file" />
				</template>
			</q-file>
			<div v-if="current_value" class="full-width">
				<q-list v-for="file_data in current_value" :key="file_data.key">
					<q-item class="q-px-none">
						<q-item-section>
							<q-item-label class="word-break">{{file_data.name}}</q-item-label>
						</q-item-section>
						<q-item-section class="attachment-btns">
							<q-btn
								v-if="!file_data.is_expired"
								round
								flat
								icon="o_download_file"
								@click="downloadFile(file_data.url)"
								color="dark"
								size="md"
								:disable='is_disabled'
							/>
							<q-btn
								v-else
								round
								flat
								class="cursor-default"
								icon="o_warning"
								color="negative"
								size="md"
							>
								<q-tooltip anchor="top middle" :delay="500">
									The file link is expired
								</q-tooltip>
							</q-btn>
							<q-btn
								round
								flat
								icon="o_cancel"
								@click="cancelAttachment(file_data)"
								color="dark"
								:disable='is_disabled'
							/>
						</q-item-section>
					</q-item>

				</q-list>
			</div>
		</div>

		<!-- DATE TIME -->
		<q-input
			v-if="type == 'DATETIME'"
			v-model="current_value"
			:label='is_required ? label + " *" : label'
			@click="date_time_dialog=true"
			:disable='is_disabled'
			clearable
			:rules="is_required ? [val => !!val || 'This field is required'] : []"
			hint="Format DD/MM/YYYY HH:MM:SS"
			class="cursor-pointer full-width"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
		>
			<template v-slot:prepend>
				<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
			</template>
		</q-input>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="current_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="current_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
		<!-- DATE -->
		<q-input
			v-if="type == 'DATE'"
			v-model="current_value"
			class="full-width cursor-pointer"
			clearable
			:label='is_required ? label + " *" : label'
			:disable="is_disabled"
			:rules="is_required ? [val => !!val || 'This field is required'] : []"
			hint="Format DD/MM/YYYY"
			@click="show_date_modal=true"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
		>
			<template v-slot:prepend>
				<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
			</template>
		</q-input>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="current_value"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<!-- TIME -->
		<q-input
			v-if="type == 'TIME'"
			v-model="current_value"
			class="full-width cursor-pointer"
			clearable
			:label='is_required ? label + " *" : label'
			:disable="is_disabled"
			:rules="is_required ? [val => !!val || 'This field is required'] : []"
			hint="Format HH:MM:SS"
			@click="show_time_modal=true"
			:error="errors['has_' + label + '_error']"
			:error-message="errors[label +'_error_message']"
		>
			<template v-slot:prepend>
				<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
			</template>
		</q-input>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="current_value"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
	</q-item>
</template>

<script>
import { mapGetters } from "vuex";
import utils from "@/services/utils.js";
import moment from 'moment-timezone'

export default {
	name: "FieldValueStart",
	props: {
		label: String,
		type: String,
		value: [String, Number, Array, Boolean, Object],
		options: Array,
		is_required: Boolean,
		is_template: Boolean,
		field_id: Number,
		field_group_id: Number,
		is_new_incident: Boolean,
		is_pii: Boolean,
		extra_style: Object,
		error: Object,
		field_group_title: String,
	},
	data() {
		return {
			current_value: null,
			error_message: "",
			is_disabled: false,
			date_time_dialog: false,
			has_attachment_error: false,
			attachment_error_message: '',
			show_date_modal: false,
			show_time_modal: false,
			errors: {},
			attachment_value: [],
			time_value: '',
			datetime_value: ""
		};
	},

	computed: {
		...mapGetters(["current_CG", "logged_user"]),
		cleaned_value: function () {
			if (this.type == "SINGLESELECT") {
				return this.current_value && this.current_value.id ? [ this.current_value.id ] : [];
			} else if (this.type == "MULTISELECT") {
				return this.current_value ? this.current_value.map(value => value.id) : [];
			} else if (this.type == "NUMERIC") {
				return this.current_value ? this.current_value : null;
			} else {
				return this.current_value;
			}
		},
	},

	methods: {
		tryUpdateValue() {
			this.validate()
			if (this.has_error){
				this.$emit("hasErrors")
				return
			}
			this.$emit("updateValue", this.cleaned_value);
		},
		validate() {
			this.errors = {}
			this.has_error = false
			if (this.is_required && !this.current_value){
				this.errors[`has_${this.label}_error`] = true
				this.errors[`${this.label}_error_message`] = "This field is required."
				this.has_error = true
			}
		},
		initSelectValues() {
			this.current_value = [];
			this.options.forEach(option => {
				this.value.forEach(val => {
					if (val === option.id) {
						if (this.type === "MULTISELECT") {
							this.current_value.push(option);
						} else if (this.type === "SINGLESELECT") {
							this.current_value = option;
						}
					}
				});
			});
		},
		setCurrentValue() {
			if (this.value && !this.is_new_incident ) {
				// If type is SELECT
				if (this.options && this.options.length) {
					this.initSelectValues();
				} else {
					this.current_value = this.value;
					if (this.type === 'ATTACHMENT') {
						const date_now = moment.utc().format('YYYY-MM-DD')
						for (let item of this.current_value) {
							const created_at = Date.parse(item.created_at)
							let years = ""
							if (this.is_pii) {
								years = this.current_CG.customer_group.incident_manager_settings.incidents_pii
							} else {
								years = this.current_CG.customer_group.incident_manager_settings.incidents_non_pii
							}
							let expiry_date = moment(created_at).add(years, 'years').format('YYYY-MM-DD');
							if (date_now >= expiry_date) {
								item['is_expired'] = true;
							}
						}
					}
				}
			} else if (this.type == "CHECKBOX") {
				this.current_value = false;  // Insetad of null
			}
		},
		async uploadAttachment(files){
			let files_temp = []
			for(let file of files){
				const file_data = {
					name: file["name"],
					customer_group: this.current_CG.customer_group.id
				};
				files_temp.push(file_data)
			}
			this.$q.loading.show({
				message: 'Please wait',
			})
			let val = await utils.uploadAttachment(files_temp, files, this.is_template);
			this.$q.loading.hide()
			if (!this.current_value){
				this.current_value = []
			}
			for (let value of val){
				this.current_value.push(value);
			}
			this.attachment_value = []
		},
		downloadFile(url){
			this.has_attachment_error = false
			this.attachment_error_message = ''
			if (!this.current_value){ return }

			let file = this.current_value[0]
			if (file.is_expired){
				this.has_error = true
				this.has_attachment_error = true
				this.attachment_error_message = 'The file link has expired.'
				return
			}
			const downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);
			downloadLink.target = '_blank';
			downloadLink.href = url;
			downloadLink.download = url;
			downloadLink.click();
		},
		cancelAttachment(file){
			this.current_value = this.current_value.filter(function(item) {
				return item.key !== file.key;
			});
		},
		resetErrors(){
			this.errors[`has_${this.label}_error`] = false
			this.errors[`${this.label}_error_message`] = ""
		},
	},

	watch: {
		current_value() {
			this.tryUpdateValue();
		},
		error:{
			deep: true,
			handler () {
				if (this.error.error_fg_title){
					if (this.error.has_error && this.error.error_fg_title && this.error.error_fg_title.length &&
						(this.error.error_field_name == this.label) && (this.error.error_fg_title == this.field_group_title)
					){
						this.errors[`has_${this.label}_error`] = true;
						this.errors[`${this.label}_error_message`] = this.error.error_message
						this.$forceUpdate();
					}
				}else{
					if (this.error.has_error && this.label && (this.error.error_field_name == this.label)){
						if(this.current_value == this.error.error_value || (typeof(this.current_value) == 'object'
							&& JSON.stringify(this.current_value) === JSON.stringify(this.error.error_value))
						){
							this.errors[`has_${this.label}_error`] = true;
							this.errors[`${this.label}_error_message`] = this.error.error_message
							this.$forceUpdate();
						}
					}
					else if (this.label && this.errors[`has_${this.label}_error`] && !this.error.has_error){
						this.resetErrors()
						this.$forceUpdate();
					}
				}
			}
		},
	},

	mounted() {
		this.tryUpdateValue();
	},

	created() {
		if (this.logged_user.customer_permission === 'SENDER' && !this.is_new_incident) {
			this.is_disabled = true
		}
		this.setCurrentValue();
		this.validate()
		if (this.label){ this.resetErrors() }
	}
};

</script>
<style lang="scss">
	.attachment-btns .q-btn__wrapper{
		padding: 0px;
	}
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.alert{
		color: var(--q-color-negative);
	}
	.attachment-btns{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}
	.word-break{
		word-break: break-all;
	}
</style>

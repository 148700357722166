<template>
	<q-card-section :style="!display ? 'display: none;' : ''">
		<div
			:style="extra_style.title"
			class="text-h6 text-left"
		>
			{{ field_group.title }}<hr>
		</div>
		<q-item-section class="field-section-style">
			<q-list
				v-for="(mapping, index) in field_group.field_mappings"
				:key="index"
				:value="mapping"
				:style = "$q.screen.gt.xs ? 'width:48%;' : 'width:100%;'"
			>
				<FieldValueStart
					:key="mapping.field.id"
					:is_pii="mapping.field.is_pii"
					:label="mapping.field.label"
					:type="mapping.field.field_type"
					:value="getValue(field_group.id, mapping.field.id)"
					:options="mapping.field.options"
					:is_required="mapping.is_required"
					:is_template="is_template"
					:field_id="mapping.field.id"
					:field_group_id="field_group.id"
					:field_group_title="field_group.title"
					:is_new_incident="is_new_incident"
					:extra_style="extra_style"
					:error="error"
					@hasErrors="hasErrors(field_group.id, mapping.field.id)"
					@updateValue="updateValue($event, field_group.id, mapping.field.id)"
				/>
			</q-list>
		</q-item-section>

		<q-item class="q-px-none" v-if="field_group.content" :style="extra_style.content">
			<p class="full-width text-left"><strong>Content: </strong>{{ field_group.content }}</p>
		</q-item>
	</q-card-section>
</template>

<script>
import FieldValueStart from "@/components/incidents/incidents/FieldValueStart.vue";
import IncidentFormAPI from "@/services/api/IncidentForm.js";
import utils from "@/services/utils.js";
import { mapGetters, mapActions } from 'vuex';

export default {
	name: "FieldGroup",
	components: { FieldValueStart },
	props: {
		field_group: Object,
		values: Array,
		custom_style: Object,
		is_template: Boolean,
		incident_state: String,
		is_new_incident: Boolean,
		extra_style: Object,
		error: Object,
	},
	data() {
		return {
			display: true
		};
	},
	computed: {
		...mapGetters(['payload', 'logged_user']),
		//technical debt - this is a workaround until the backend data is synced.
		conditions: function() {
			/* 
				We use this computed property to format the conditions, because they are not in the same format in the database.
				If the field type is Single/Multiselect the value is the selected field option(s)
				For the other types the value is string
			*/
			let conditions = [];
			for(let condition of this.field_group.display_conditions){
				let condition_value = condition.value;
				let formated_condition = {};
				// check if the field type is Single/Multiselect
				if(condition.value instanceof Object){
					let values_ids = [];
					// If the field type is Multiselect we have Array in the database. If it is Singleselect, we have JSON object
					// We need the data to be Array
					if(!(condition.value instanceof Array)){
						condition.value = [condition.value];
					}
					for(let value of condition.value){
						values_ids.push(value.id);
					}
					condition_value = values_ids;
				}
				formated_condition['values'] = condition_value;
				formated_condition['field'] = condition.field_id;
				formated_condition['field_group'] = condition.field_group_id;
				if(condition.type){
					formated_condition['type'] = condition.type;
				}
				conditions.push(formated_condition)
			}
			return conditions;
		}
	},
	methods: {
		...mapActions(['updatePayload', 'updateCurrentIncident', 'updateNewIncident']),

		updateValue(values, field_group, field) {
			if(values && values.length == 0){
				values = null
			}
			this.updatePayload({ values, field_group, field });
		},

		async hasErrors(field_group, field) {
			await this.checkConditions()
			if (this.display) {
				this.$store.commit('setMappingWithErrors', { field_group, field });
			}
		},

		getValue(field_group, field) {
			return utils.getValue(this.values, field_group, field);
		},

		async checkConditions() {
			const are_conditions_met = await utils.areConditionsMet(this.conditions, this.payload, this.incident_state, this.logged_user.customer_permission, this.is_template);
			if (are_conditions_met) {
				this.$store.commit('unsetIgnoredFieldGroups', this.field_group.id);
				this.display = true;
			} else {
				if (this.display) {
					this.$store.commit('setIgnoredFieldGroups', this.field_group.id);
				}
				this.display = false;
			}
		},

		checkHasConditions() {
			if (this.field_group.display_conditions.length) {
				this.display = false;
				this.$store.commit('setIgnoredFieldGroups', this.field_group.id);
			} else {
				this.display = true;
			}
		},
		async getConditions() {
			/* 
				We use this computed property to format the conditions, because they are not in the same format in the database.
				If the field type is Single/Multiselect the value is the selected field option(s)
				For the other types the value is string
			*/
			let conditions = [];
			for(let condition of this.field_group.display_conditions){
				let condition_value = condition.value;
				let formated_condition = {};
				// check if the field type is Single/Multiselect
				if(condition.value instanceof Object){
					let values_ids = [];
					// If the field type is Multiselect we have Array in the database. If it is Singleselect, we have JSON object
					// We need the data to be Array
					if(!(condition.value instanceof Array)){
						condition.value = [condition.value];
					}
					for(let value of condition.value){
						values_ids.push(value.id);
					}
					condition_value = values_ids;
				}
				formated_condition['values'] = condition_value;
				formated_condition['field'] = condition.field_id;
				formated_condition['field_group'] = condition.field_group_id;
				if(condition.type){
					formated_condition['type'] = condition.type;
				}
				if (condition.field_id){
					if (this.is_template){
						formated_condition['field_type'] = await IncidentFormAPI.getTemplateFieldType(condition.field_id)
					}else{
						formated_condition['field_type'] = await IncidentFormAPI.getFieldType(condition.field_id)
					}
				}
				conditions.push(formated_condition)
			}
			this.conditions = conditions
		}
	},

	watch: {
		payload: {
			deep: true,
			handler() {
				this.checkConditions()
			}
		},
	},

	created() {
		this.checkHasConditions();
	},
	destroyed(){
		this.updateCurrentIncident()
		this.updateNewIncident()
	}
};
</script>
<style scoped>
.field-section-style{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
</style>
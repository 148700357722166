<template>
	<q-card-section :class="is_edit_incident ? 'q-pa-md' : 'q-pa-none'">
		<h1
			class="text-h5 q-pb-md q-my-md text-left fields-tab-header"
			:style="custom_style ? custom_style.title : ''"
		>
			<span v-if="title_anonymous_user">{{ title_anonymous_user }}</span>
			<span v-else-if="incident.title">{{incident.title}}</span>
			<span v-else>{{incident.name}}</span>
		</h1>
		<div
			v-for="field_group in current_incident.field_groups"
			:key="field_group.order"
			:value="field_group"
		>
			<FieldGroup
				:field_group="field_group"
				:values="current_incident.values"
				:extra_style="extra_style"
				:is_template="is_template"
				:incident_state="current_incident.state"
				:is_new_incident="is_new_incident"
				:error="error"
			/>
		</div>
		<q-card-actions align="right" class="q-pa-none">
			<q-btn
				v-if="!is_template"
				flat
				label="Cancel"
				color="primary"
				@click="cancel"
			/>
			<q-btn
				v-else
				flat
				label="Cancel"
				color="primary"
				@click="exit"
			/>
			<q-btn
				flat
				:label="is_new_incident ? 'Create' : 'Save'"
				color="primary"
				:disable="prevent_update"
				@click="update"
			/>
		</q-card-actions>
	</q-card-section>
</template>

<script>
import FieldGroup from "@/components/incidents/incidents/IncidentFieldGroup.vue";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment-timezone'

export default {
	name: "Incident",
	components: { FieldGroup },
	props: {
		incident: Object,
		is_template: Boolean,
		custom_style: Object,
		template_module_name: String,
		title_anonymous_user: String,
	},
	data() {
		return {
			title: this.title_anonymous_user ? this.title_anonymous_user : this.incident.name,
			incident_created_at: moment.tz(this.incident.created_at, 'UTC'),
			is_edit_incident: window.location.pathname.includes('/edit'),
			error: {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_value: null,
				error_fg_title: '',
			}
		}
	},
	computed: {
		...mapGetters(["current_incident", "prevent_update", "is_new_incident", "mappings_with_errors"]),
		module_name: function() {
			if (!this.is_template) {
				return this.$store.getters.module_name;
			}
			return this.template_module_name ? this.template_module_name : "...";
		},
		extra_style: function() {
			if (!this.custom_style) {
				return {
					background: {},
					title: {},
					content: {},
					label: {}
				};
			}
			return this.custom_style;
		},
	},
	methods: {
		...mapActions(["updateIncident", "updateIncidentFromTemplate", "updateCurrentIncident", 'updateNewIncident']),
		async update() {
			this.error = {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_value: null,
				error_fg_title: ''
			}
			if (!this.title){
				this.title = null
			}
			let data = {'is_template': this.is_template, 'incident_title': this.title}
			this.$q.loading.show({
				delay: 400,
			})
			await this.updateIncident(data);
			this.$q.loading.hide()
			if(this.current_incident.error_message){
				let error_message = this.current_incident.error_message
				if (error_message.for_field){
					this.error = {
						'has_error': true,
						'error_message': error_message.message,
						'error_field_name': error_message.for_field,
						'error_value': error_message.value,
						'error_fg_title': error_message.fg_title
					}
					this.notify('The form could not be updated. Please fix the errors.')
					return
				}
				this.notify(error_message.message)
				return
			}
			this.notify('The form was successfully submitted.')
			if (!this.is_template){
				this.$router.push({ 'name': 'Start'})
			}
			this.updateCurrentIncident()
			this.updateNewIncident()
			this.$emit('finish');
		},
		cancel() {
			this.updateCurrentIncident()
			this.updateNewIncident()
			this.$router.push({ 'name': 'Start'})
		},
		exit() {
			this.updateCurrentIncident()
			this.updateNewIncident()
			this.$emit('cancel-incident')
		},
		notify(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
	},
};
</script>